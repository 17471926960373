import { createSettingsParams, SettingsParamType } from '@wix/tpa-settings';

import {
  IChallengeSettings,
  TextAlignment,
  ImageRatio,
  CropSelection,
  ParticipantLayout,
  VisitorPageLayout,
  ImageAlignment,
  VerticalImageAlignment,
} from './Settings/challengeSettings/challengeSettings.types';
import { RolesState } from './Widget/settingsEvents';

export default createSettingsParams<IChallengeSettings>({
  participantLayout: {
    getDefaultValue() {
      return ParticipantLayout.LIST;
    },
  },
  visitorPageImageCropping: {
    getDefaultValue() {
      return CropSelection.Middle;
    },
  },
  visitorPageImageAlignment: {
    getDefaultValue() {
      return ImageAlignment.left;
    },
  },
  visitorPageVerticalImageAlignment: {
    getDefaultValue() {
      return VerticalImageAlignment.top;
    },
  },
  visitorPageLayout: {
    getDefaultValue() {
      return VisitorPageLayout.classic;
    },
  },
  visitorPageHeaderAlignment: {
    getDefaultValue() {
      return TextAlignment.Left;
    },
  },
  displayGroup: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  /**
   * sidebar layout
   */
  sidebarLayoutTextAlignment: {
    getDefaultValue() {
      return TextAlignment.Left;
    },
  },
  sidebarLayoutSpace: {
    type: SettingsParamType.Number,
    inheritDesktop: false,
    getDefaultValue() {
      return 0;
    },
  },
  /**
   * participant page list layout
   */
  listLayoutHeaderAlignment: {
    getDefaultValue() {
      return TextAlignment.Left;
    },
  },
  listLayoutContentAlignment: {
    getDefaultValue() {
      return TextAlignment.Left;
    },
  },
  listLayoutDisplayChallengeParticipants: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  listLayoutDisplayChallengeSteps: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  listLayoutDisplayChallengeDuration: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  /**
   * participant page sidebar layout
   */
  sidebarLayoutDisplayChallengeDate: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  sidebarLayoutDisplayChallengeDuration: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  sidebarLayoutDisplayChallengeSteps: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  sidebarLayoutDisplayChallengeParticipants: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  /**
   * visitor settings
   */
  editorParticipantRole: {
    inheritDesktop: false,
    getDefaultValue() {
      return RolesState.Visitor;
    },
  },
  headerTextAlignment: {
    getDefaultValue() {
      return TextAlignment.Left;
    },
  },
  contentTextAlignment: {
    getDefaultValue() {
      return TextAlignment.Left;
    },
  },
  imageRatio: {
    getDefaultValue() {
      return ImageRatio.HD;
    },
  },
  cropSelection: {
    getDefaultValue() {
      return CropSelection.Middle;
    },
  },
  displayHeaderDate: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  displayHeaderPrice: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  displayHeaderImage: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  displayChallengeDuration: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  displayChallengeSteps: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  displayChallengeParticipants: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  displayOwner: {
    getDefaultValue() {
      return true;
    },
  },
  displayOneApp: {
    getDefaultValue() {
      return true;
    },
  },
  displayDivider: {
    getDefaultValue() {
      return true;
    },
  },
  displayTopJoinButton: {
    getDefaultValue() {
      return true;
    },
  },
  displayPriceForFreePrograms: {
    getDefaultValue() {
      return true;
    },
  },
  socialSharingIsEnabled: {
    getDefaultValue() {
      return true;
    },
  },
  socialSharingForParticipantIsEnabled: {
    getDefaultValue() {
      return true;
    },
  },
  textJoinButton: {
    type: SettingsParamType.Text,
    getDefaultValue({ t }) {
      return t('challenge.page.join');
    },
  },

  /**
   * visitor settings: agenda
   */

  agendaIsEnabled: {
    getDefaultValue() {
      return false;
    },
  },
  agendaShowTitle: {
    getDefaultValue() {
      return true;
    },
  },
  agendaTitleText: {
    type: SettingsParamType.Text,
    getDefaultValue({ t }) {
      return t('settings.tab.sections.agenda.section-title-default');
    },
  },
  agendaStepsShown: {
    getDefaultValue() {
      return 5;
    },
  },
  agendaShowMoreText: {
    type: SettingsParamType.Text,
    getDefaultValue({ t }) {
      return t('settings.tab.sections.agenda.load-all-steps-default');
    },
  },
  aboutVstText: {
    type: SettingsParamType.Text,
    getDefaultValue({ t }) {
      return t('challenge.page.content-title');
    },
  },
  completeButtonTxt: {
    type: SettingsParamType.Text,
    getDefaultValue({ t }) {
      return t('challenge.page.steps.complete');
    },
  },
  overviewTabDisplay: {
    getDefaultValue() {
      return true;
    },
  },
});
